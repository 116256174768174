import styled, { keyframes } from 'styled-components'

export const FrameWrapper = styled.div`
  background: ${(props) => props.theme.bg};
  background-image: linear-gradient(to right, rgba(151, 151, 151, 0.15), 1px, transparent 1px);
  background-size: 220px;
  width: 100vw;
  min-height: 100vh;
  height: 100%;
  overflow-y: scroll;
  display: flex;
  justify-content: center;
  position: relative;

  @media (max-width: 600px) {
    overflow-x: hidden;
  }
`

export const Content = styled.div`
  position: relative;
  max-width: 1000px;
  min-width: 1000px;
  min-height: 100%;

  @media (max-width: 600px) {
    max-width: 100vw;
    min-width: 100vw;
  }
`

export const LogoWrapper = styled.span`
  position: absolute;
  top: 2rem;
  left: 2rem;
`

export const Footer = styled.div`
  width: 100vw;
  margin-left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  position: relative;
`

export const bounceAnimation = (
  initialMarginBottom: string,
  finalMarginBottom: string
) => keyframes`
	0% {
		margin-top: ${initialMarginBottom};
    opacity: 0;
	}
	100% {
    margin-top: ${finalMarginBottom};
    opacity: 1;
	}
  `

export const grayscaleAnimation = keyframes`
  0% {
    filter: grayscale(100%);
  }
  100% {
    filter: grayscale(0%);
  }
`
