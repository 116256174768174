import styled from 'styled-components'

export const TitleBig = styled.span<{ pixelated?: boolean }>`
  font-size: ${(props) =>
    props.pixelated ? props.theme.fontSize.titleBigPixelated : props.theme.fontSize.titleBig};
  color: ${(props) => (props.pixelated ? props.theme.darkColor : props.theme.boldColor)};
  ${(props) =>
    props.pixelated
      ? `
    font-family: ${props.theme.fontPixelated};
    `
      : null}
`

export const TitleMedium = styled(TitleBig)`
  font-size: ${(props) => props.theme.fontSize.titleMedium};
`

export const Green = styled.span`
  color: ${(props) => props.theme.green};
`
