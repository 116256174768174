import React from 'react'
import { BrowserRouter } from 'react-router-dom'
// import { Sidebar } from './Components/Sidebar'
import styled, { ThemeProvider } from 'styled-components'
import { Frame } from './Components/Frame'
import { theme } from './Components/Theme'

const AppWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  overflow: hidden;
`

function App() {
  return (
    <ThemeProvider theme={theme}>
      <AppWrapper>
        <BrowserRouter>
          <Frame />
          {/* <Sidebar /> */}
        </BrowserRouter>
      </AppWrapper>
    </ThemeProvider>
  )
}

export default App
