import React from 'react'
import styled from 'styled-components'

import Hero1 from '../../Assets/Hero/Hero1.png'
import Hero2 from '../../Assets/Hero/Hero2.png'
import Hero4 from '../../Assets/Hero/Hero4.png'

const HeroWrapper = styled.div`
  z-index: 0;
  position: absolute;
  top: -6rem;
  right: 0;

  color: #fff;

  img {
    width: 15rem;
    height: auto;
  }

  @media (max-width: 600px) {
    width: 45rem;
    right: -25rem;
  }
`

const FirstRow = styled.div`
  margin-left: 7.5rem;
`

const SecondRow = styled.div`
  margin-left: 15rem;
  margin-top: -5rem;
`

export const Hero = () => {
  return (
    <HeroWrapper>
      <FirstRow>
        <img src={Hero4} alt="unifi" />
        <img src={Hero2} alt="unifi" />
      </FirstRow>
      <SecondRow>
        {/* <img src={Hero3} alt="unifi" /> */}
        <img src={Hero1} alt="unifi" />
      </SecondRow>
    </HeroWrapper>
  )
}
