import styled from 'styled-components'

export const PrimaryButton = styled.button`
  border: 0;
  background: ${(props) => props.theme.green};
  font-family: 'Neue Bit';
  padding: 1.5rem 3rem;
  font-size: 1.25rem;
  cursor: pointer;
  transition: background 1s, color 1s;

  &:hover {
    background: #000;
    color: #fff;
  }
`
