export const theme = {
  bg: '#e7e6e4',
  altBg: '#252D29',
  green: '#39f998',
  boldColor: '#76726f',
  darkColor: '#272727',
  fontPixelated: 'Neue Bit',
  fontSize: {
    titleBig: '4rem',
    titleBigPixelated: '5.5rem',
    titleMedium: '2rem'
  }
}
