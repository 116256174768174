import React from 'react'
import { Content } from '../../Components/Frame/Styles'
import { TitleBig, TitleMedium } from '../../Styles/Titles'
import { Hero } from './Hero'
import {
  HorizontalWrapper,
  HomeFooter,
  HomeWrapper,
  RightContent,
  LinkButton,
  LinksWrapper
} from './Styles'

export const Home = () => {
  return (
    <>
      <HomeWrapper>
        <TitleMedium pixelated>APPLICATIONS</TitleMedium>
        <p></p>
        <HorizontalWrapper>
          <div style={{ width: '100%' }}>
            <TitleBig style={{ color: '#000', fontSize: '2rem' }}>
              Welcome to the UNIFI Ecosystem
            </TitleBig>
          </div>
          <RightContent>
            {/* <span>Designed with the economics to scale and support everyone.</span> */}
          </RightContent>
        </HorizontalWrapper>
      </HomeWrapper>

      <HomeFooter>
        <Content>
          <LinksWrapper>
            <LinkButton onClick={() => window.open('https://gov.unifiprotocol.com/')}>
              UNIFI DAO
              <br />
              Governance
            </LinkButton>
            <LinkButton onClick={() => window.open('https://utrade.finance/')}>
              uTrade
              <br />
              AMM
            </LinkButton>
            <LinkButton onClick={() => window.open('https://app.unifiprotocol.com/bridge')}>
              uBridge
              <br />
              UNIFI Bridge
            </LinkButton>
            <LinkButton onClick={() => window.open('https://app.unifiprotocol.com/nft')}>
              NFT
              <br />
              Anniversary Collection
            </LinkButton>
            <LinkButton onClick={() => window.open('https://blog.unifiprotocol.com/')}>
              Blog
            </LinkButton>
          </LinksWrapper>
        </Content>
      </HomeFooter>

      <Hero />
    </>
  )
}
