import React from 'react'
import { AnimatePresence, motion } from 'framer-motion'
import { useLocation, useRoutes } from 'react-router-dom'
import { ReactComponent as UnifiLogo } from '../../Assets/unifi.svg'
import { Content, FrameWrapper, LogoWrapper } from './Styles'
import { Home } from '../../Pages/Home'

export const Frame = () => {
  const location = useLocation()
  const element = useRoutes([
    {
      path: '/',
      element: <Home />
    }
  ])

  return (
    <FrameWrapper>
      <LogoWrapper>
        <UnifiLogo />
      </LogoWrapper>
      <AnimatePresence mode="wait">
        {element &&
          React.cloneElement(
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.5 }}
            >
              <Content>{element}</Content>
            </motion.div>,
            { key: location.pathname }
          )}
      </AnimatePresence>
    </FrameWrapper>
  )
}
