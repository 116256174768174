import styled from 'styled-components'
import bgGrid from '../../Assets/Backgrounds/grid.png'
import { Footer } from '../../Components/Frame/Styles'
import { PrimaryButton } from '../../Components/PrimaryButton'

export const HomeWrapper = styled.div`
  padding-top: 8rem;
  position: relative;
  z-index: 2;

  @media (max-width: 600px) {
    padding: 0 2rem;
    padding-top: 9rem;
  }
`

export const HorizontalWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: 600px) {
    flex-direction: column;
  }
`

export const RightContent = styled.div`
  margin-left: 3rem;
  padding-top: 1rem;
  display: flex;
  justify-content: center;
  font-size: 2rem;
  width: 50%;
`

export const HomeFooter = styled(Footer)`
  position: absolute;
  bottom: 0;
  min-height: 40vh;
  z-index: 0;
  background: url(${bgGrid}) ${(props) => props.theme.darkColor} center center;
  padding: 1rem 0;
  color: #fff;
`

export const LinkButton = styled(PrimaryButton)`
  margin: 1rem;
  width: 40%;

  @media (max-width: 600px) {
    margin: 0.5rem 0;
    width: 100%;
  }
`

export const LinksWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  padding-top: 1rem;

  @media (max-width: 600px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 1rem;
  }
`
